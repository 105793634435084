/* Profile Stats Section */
.profile-stats {
    display: flex;
    justify-content: center; /* Centers the stats horizontally */
    align-items: center;
    margin-top: 40px; /* Adjust margin as needed */
    padding: 40px 0; /* Add padding to top and bottom */
    color:  #c9d1d9; /* White text color */
    border-radius: 10px;
}

.stat-item {
    flex: 1;
    font-size: 4rem; 
    text-align: center;
    font-weight: 800;
    padding: 0 20px;
}

.stat-item h2 {
    font-size: 4rem; 
    margin-bottom: 10px;
    font-weight: 800;
}

.stat-item p {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensures padding/borders are part of element size */
}

html, body {
    height: 100%; /* Ensure they occupy full height */
    width: 100%; /* Ensure they occupy full width */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Full-height container */
.home-container {
    padding: 400px 0 0;
    width: 100%;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.93), rgba(10, 10, 92, 0.9));
    min-height: 100vh; /* Ensure the container covers the full height */
    margin: 0;
    /*padding: 0;*/ /*remove this if you need background image*/
    box-sizing: border-box;
}

/* Background Section */
.background-top {
    width: 100%;
    height: 50vh; /* Adjust as needed */
    background-size: cover;
    position: relative;
}
.background-top::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Add semi-transparent overlay */
    z-index: 2;
}

/* The remaining background color after the image */
.background-bottom {
    /*background: linear-gradient(135deg, rgba(0, 0, 0, 0.93), rgba(10, 10, 92, 0.9));
    /*background: linear-gradient(135deg, #000000, #0a0a5c);*/
    padding-top: 100px;
    min-height: 50vh;
    position: relative;
    width: 100%;
}

/* Overlay to fade the image */
.background-top::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1); /* White overlay with 30% transparency */
    z-index: 2;
}

/* Layout for intro section */
.intro-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 150px;
    margin-bottom: 60px;
    /*border-bottom: 1px solid #ddd;*/ /*if we waan create border*/
    position: relative; /* To contain the absolutely positioned avatar */
}


/* Avatar with absolute positioning */
.intro-avatar {
    position: absolute;
    top: -320px;
    left: 10%;
    z-index: 2;
    width: 300px;
}

.avatar-full-body {
    width: 230px;
    height: auto;
    object-fit: contain;
}

/* Intro text */
.intro-text {
    z-index: 1;
    margin-left: 40%;
    color:  #c9d1d9;
    padding-top: 20px;
    margin-top: -100px; /*comment this out when need an image*/
    padding: 0 19px;
}

.intro-text h1 {
    font-size: 2.5rem;
    color: #58a6ff;
}

.intro-text h2 {
    font-size: 1.5rem;
    font-weight: lighter;
    margin-bottom: 20px;
}

.intro-text p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #58a6ff;
    color:  #c9d1d9;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

/*about me section coulur scheme*/
.about-me-section {
    scroll-margin-top: 100px; /* to keep scroll perfect and not hidden under nav bar*/
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.63), rgba(51, 51, 99, 0.6));
    /*  background: linear-gradient(135deg, rgba(0, 0, 0, 0.93), rgba(10, 10, 92, 0.9)); make this bit light background-color:#0d1117;; #f5f5f5; white Light gray for contrast  */
    padding: 20px;
    text-align: justify;
    color: #c9d1d9;
}

/* Fixed Close Button on Top-Right */
.close-button {
    position: relative;
    top: -140px;  /* Move to the top */
    left: 420px; /* Move to the right */
    background: transparent;
    border: none;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    z-index: 0; /* Ensure it stays above chatbox */
    color: red;
}

.section-content a {
    color:#58a6ff;  /* Default white text */
    text-decoration: none;  /* Remove underline by default */
    transition: all 0.3s ease;  /* Smooth transition for hover effect */
}

.section-content a:hover {
    text-decoration: underline;  /* Underline on hover */
}
/* What I Bring Section */
.what-i-bring {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-top: 40px;
    padding: 0 20px;
    padding-bottom: 40px;
    color:  #c9d1d9;
}
/* What I Bring Section Parent */
.what-i-bring-section {
    margin-top: 10px;
    color: #58a6ff;
}

.strength-card {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid  #c9d1d9;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: #2a2f3b;
}

.strength-card h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color:#58a6ff;
}

.strength-card p {
    font-size: 1rem;
}

.strength-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); 
}

.social-icons {
    display: flex;
    justify-content: center; /* Center the icons */
    gap: 1rem; /* Add spacing between icons */
    margin-bottom: -2rem; /* Add spacing below the icons */
}

.social-icons a {
    color:  #c9d1d9; /* Set icon color (light blue) */
    font-size: 2.0rem; /* Adjust icon size */
    transition: color 0.3s ease; /* Smooth color transition */
}

.social-icons a:hover {
    color: #58a6ff; /* Darker blue on hover */
}

.experience-section {
    scroll-margin-top: 100px; /* to keep scroll perfect and not hidden under nav bar*/
    padding: 60px 20px;
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.63), rgba(51, 51, 99, 0.6));
    /*  background-color: #0d1117;  Dark background for contrast */
    color:  #c9d1d9;
    text-align: justify;
}

.experience-title {
    font-size: 2.5em;
    margin-bottom: 40px;
    text-align: center;
    font-weight: 600;
    color: #58a6ff;
}

.experience-timeline {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
/*FFFFIXXXX THISSSS*/
.timeline-entry {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 30px;
    border-radius: 12px;
    transition: transform 0.3s ease;
    cursor: pointer;
    flex-grow: 1;  
    min-height: auto; 
    background-color: #2a2f3b !important;
    border: 1px solid rgba(255, 255, 255, 0.2); /* Slim white border */
}

/* Highlight Active Entry by Default 
.timeline-entry.active {
    background-color: #1f2937;
    border-left: 4px solid  #58a6ff;  /* Blue highlight by default 
    transition: border 0.3s ease, background-color 0.3s ease;
}*/
/*
.timeline-entry a {
    color:  #58a6ff; /* Hex code from the image 
    text-decoration: none; /* Remove underline 
}*/
/*
.timeline-entry a:hover {
    color: #58a6ff; /* Keep the color the same on hover */
   /* text-decoration: underline;  /* Optional: underline on hover 
}
*/

/* On Hover - Remove Blue Highlight 
.timeline-entry:hover {
    border-left: 4px solid transparent;
    background-color: #2d333b; /* Slightly lighter hover effect 
    transform: translateY(-5px);
}*/
/*
/* Smooth Hover In and Out Effect 
.timeline-entry:not(.active):hover {
    border-left: 4px solid  #58a6ff;  /* Add subtle blue border when hovering 
    background-color: #2a2f3b;
}*/


.timeline-date {
    font-size: 1.1em;
    font-weight: 500;
    color: #8b949e;
    background-color: #2a2f3b;
    min-width: 140px;
}

.timeline-content {
    background-color: #2a2f3b;
    flex: 1;
}

.timeline-content h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color:  #c9d1d9;
}

.timeline-content span {
    font-size: 0.9em;
    color: #58a6ff;
}

.timeline-content p {
    color:  #c9d1d9;
    margin-bottom: 20px;
    line-height: 1.6;
}

.tech-stack {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.tech-stack span {
    background-color: #21262d;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9em;
    color:  #c9d1d9;
    transition: background-color 0.3s;
}

.tech-stack span:hover {
    background-color: #2d333b;
}

/* Highlight Active Entry */
.active {
    background-color: #1f2937;
    border-left: 4px solid #58a6ff;
}

.skills-section {
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.63), rgba(51, 51, 99, 0.6));
    text-align: center;    
    scroll-margin-top: 100px;
    padding: 20px;  /* Increase padding to add space */
}

.section-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    margin-top: 10px;
    text-align: center;
    color: #58a6ff;
}

.skills-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns layout */
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.skill-card {
    text-align: center;
    padding: 20px;
}

.skill-card img {
    width: 100px;  /* Adjust icon size */
    margin-bottom: 20px;
}

.skill-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #58a6ff;
}

.skill-card p {
    font-size: 1rem;
    color:  #c9d1d9;
}

.projects-section {
    scroll-margin-top: 80px; /* to keep scroll perfect and not hidden under nav bar*/
    padding: 60px 20px;
    text-align: justify;
    color: #c9d1d9;
}

.projects-title {
    color: #58a6ff;
    font-size: 2.5em;
    margin-bottom: 40px;
    text-align: center;
    font-weight: 600;
}

.projects-timeline {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.timeline-entry {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 30px;
    border-radius: 12px;
    background-color: #161b22;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.timeline-entry:hover {
    background-color: #1f2937;
    transform: translateY(-5px);
}

.timeline-date {
    font-size: 1.1em;
    font-weight: 500;
    color: #8b949e;
    min-width: 140px;
}

.timeline-content {
    flex: 1;
}

.timeline-content h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #e6edf3;
}

.timeline-content span a {
    font-size: 0.9em;
    color: #58a6ff;
    text-decoration: none;
}

.timeline-content span a:hover {
    text-decoration: underline;
}

.timeline-content p {
    color: #adbac7;
    margin-bottom: 20px;
    line-height: 1.6;
}
.project-archive-link {
    text-align: center;
    margin-top: 40px;
}

.project-archive-link a {
    font-size: 1.2rem;
    color: #58a6ff;
    text-decoration: none;
    font-weight: 600;
    transition: color 0.3s ease;
}

.project-archive-link a:hover {
    text-decoration: underline;
    color: #82cfff;
}

.education-section {
    padding: 60px 20px;
    color: #c9d1d9;
    text-align: center;
}

.education-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    font-weight: 600;
    color: #58a6ff;
}

.education-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* Three columns */
    gap: 30px;
    justify-content: center;
}

.education-card {
    background-color: #2a2f3b;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.education-card:hover {
    transform: translateY(-5px);
}

.education-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #c9d1d9;
}

.education-card p {
    font-size: 1rem;
    margin: 5px 0;
    color: #c9d1d9;
}



/* Mobile responsiveness */
@media (max-width: 768px) {
    /* Adjust avatar */
    .intro-avatar {
        top: -400px; /*increase in nagative to move it up*/
        flex: 1 1 auto;
        margin-bottom: 20px;
        left: 1%;
        z-index: 0;
        width: 0px;
        height: auto;
    }

    .avatar-full-body {
        width: 90px;
        height: auto;
        object-fit: contain;
    }

    /* Center and move intro text slightly to the left */
    .intro-text {
        flex: 1 1 100%;
        text-align: center; /* Center the text */
        margin-bottom: 20px;
        padding: 0 10px; /* Adjust padding */
        max-width: 90%; /* Ensure text stays within screen bounds */
        margin-left: -10px; /* Move the text slightly to the left */
        word-wrap: break-word; /* Handle long words */
        margin-top: -130px; /* Add this line to move the section down  comment out if need image*/
    }

    /* Stack avatar and text vertically */
    .intro-section {
        flex-direction: column-reverse;
        text-align: center; /* Center align the content */
        padding-bottom: 20px;
        margin-bottom: 0;
        padding: 20px;
    }

    /* Adjust font sizes */
    .intro-text h1 {
        font-size: 1.8rem;
        overflow-wrap: break-word;
    }
    
    .intro-text h2 {
        font-size: 1.2rem;
        margin-bottom: 15px;
        overflow-wrap: break-word;
    }

    .intro-text p {
        font-size: 0.9rem;
        margin-bottom: 15px;
        line-height: 1.4;
        overflow-wrap: break-word;
    }
    /* Adjust button size for mobile */
    .cta-button {
        padding: 8px 16px;
        font-size: 1rem;
    }
    .what-i-bring {
        grid-template-columns: 1fr;  /* Single column for mobile */
        gap: 20px;
    }
    .skills-grid {
        grid-template-columns: repeat(2, 1fr);  /* 2 columns */
        gap: 30px;
    }
    .projects-timeline{
        grid-template-columns: 1fr;  /* Single column for mobile */
        justify-content: center;
        gap: 20px;
    }
    .experience-timeline{
        grid-template-columns: 1fr;  /* Single column for mobile */
        justify-content: center;
        gap: 20px;
    }
    .experience-timeline,
    .projects-timeline {
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* Aligns text and content to the left */
        text-align: left; /* Ensures text aligns properly */
        gap: 20px;
    }

    .timeline-entry {
        display: flex;
        flex-direction: column; /* Stack title and content */
        align-items: flex-start;
        text-align: left;
        width: 100%; /* Ensure full width */
        padding: 20px;
    }

    .timeline-content {
        width: 100%; /* Ensure it takes full width */
    }

    .timeline-date {
        text-align: left; /* Align date to the left */
        font-size: 1rem;
        font-weight: bold;
    }

    .timeline-content h3 {
        font-size: 1.3rem; /* Adjust title size for mobile */
    }

    .timeline-content p {
        font-size: 1rem; /* Adjust text size for better readability */
        line-height: 1.5;
    }
    .close-button {
        position: absolute;
        top: 10px; /* Adjust to fit inside the chatbox */
        right: 10px; /* Ensure it's at the top-right */
        left: auto; /* Remove left positioning */
        font-size: 18px; /* Slightly smaller button */
        z-index: 9999; /* Keep it above everything */
        display: block !important; /* Ensure it's always visible */
    }
    .education-grid {
        grid-template-columns: 1fr;  /* Single column for mobile */
        gap: 20px;
    }

}
