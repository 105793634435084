/* Base Navbar Styling */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.93), rgba(10, 10, 92, 0.9));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 9999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
}

.navbar-logo {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  transition: all 0.3s ease;
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #3b82f6;
}

/* Mobile Menu Icon */
.menu-icon {
  display: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    z-index: 10000;
  }

  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.4s ease, opacity 0.4s ease;
  }

  .navbar-links li {
    padding: 0.75rem 1rem;
    width: 100%;
  }

  .navbar-links li a {
    font-size: 1.2rem;
  }

  /* When Menu is Open */
  .navbar.open .navbar-links {
    max-height: 500px;
    opacity: 1;
  }
}
