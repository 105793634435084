/* Transparent chat container */
.chat-box.transparent {
   /* background-color: transparent; /* Fully transparent background for chat box */
   background-color: rgba(255, 255, 255, 0.2); /* Adjust 0.7 to control opacity */
}

/* Initial position and style for chat container before chat opens */
.combined-chat-container {
    position: absolute;
    bottom: 100px;
    right: -88px; /* Positions the initial hello message near the avatar */
    top: -20px; /* Adjusts the vertical position */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    gap: 5px; /* Space between hello message and chat button */
}

/* Position and style for chat container when the chat is opened */
.combined-chatbot-container {
    position: absolute;
    bottom: 100px;
    right: 40px; /* Adjusted position for when the chat is open */
    top: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
    gap: 5px; /* Space between messages and input area */
}

/* Chat Button Styling */
.chat-button {
    padding: 10px 15px;
    font-size: 14px;
    color: white;
    background-color: #28a745;
    border: none;
    border-radius: 15px 15px 15px 0px; /* Rounded button with a tail on one side */
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Adds a slight shadow */
    white-space: nowrap; /* Prevents text wrapping */
}

/* Chat box styling */
.chat-box {
    position: absolute;
    top: -149px; /* Adjusts the vertical position of the chat box */
    left: 100%; /* Aligns chat box with the button */
    width: 400px; /* Sets the width of the chat box */
    padding: 10px;
    background-color: white; /* White background for chat messages */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for chat box */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    z-index: 2; /* Ensures chat box is above other elements */
}

/* Input field styling */
.chat-box input[type="text"] {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;
}

/* Chat messages container styling */
.chat-messages {
    margin-bottom: 10px;
    overflow-y: auto;
    max-height: 250px; /* Limits height of message area for scrolling */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns messages to the left */
}

/* User message styling */
.user-message {
    background-color: #d4f8d4; /* Light green background for user messages */
    color: #333;
    align-self: flex-start; /* Aligns messages to the left */
    padding: 8px 12px;
    border-radius: 15px; /* Rounded corners for message bubble */
    margin: 5px 0; /* Space between each message */
    max-width: 90%; /* Limits width of the message bubble */
    word-wrap: break-word; /* Wraps long words to the next line */
    display: inline-block; /* Message bubble takes only as much width as needed */
    text-align: left;
}

/* Bot message styling */
.bot-message {
    background-color: #f0f0f0; /* Light grey background for bot messages */
    color: #333;
    align-self: flex-start;
    padding: 8px 12px;
    border-radius: 15px;
    margin: 5px 0;
    max-width: 90%;
    word-wrap: break-word;
    display: inline-block;
    text-align: left;
}

/* General chat message styling */
.chat-message {
    margin-bottom: 5px;
    width: auto; /* Adjusts width to content */
    box-sizing: border-box; /* Includes padding in width */
}

/* Send Button Styling */
.chat-box button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.chat-box button:hover {
    background-color: #218838; /* Darkens button on hover */
}

/* Hello Message Styling */
.hello-message {
    font-size: 16px;
    background-color: #f0f0f0;
    color: #333;
    padding: 8px 12px;
    border-radius: 15px 15px 15px 5px; /* Bubble shape with tail */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Slight shadow */
    max-width: 250px; /* Limits width of hello message bubble */
    word-wrap: break-word;
    text-align: left;
    margin-bottom: 5px; /* Space between hello message and button */
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    /* Adjust chat container position for smaller screens */
    .combined-chat-container {
        bottom: 20px;
        left: -5px;
        gap: 4px; /* Reduce space between elements */
        top: 20px;
        align-items: flex-start;
    }

    /* Adjust chat container position after chat opens */
    .combined-chatbot-container {
        bottom: 100px;
        right: 10px;
        top: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: transparent;
        gap: 5px;
    }

    /* Smaller font and padding for hello message */
    .hello-message {
        font-size: 12px;
        padding: 5px 8px;
        margin-bottom: 5px;
        margin-left: 110px; /* Aligns closer to chat button */
    }

    /* Adjustments for chat button */
    .chat-button {
        font-size: 12px;
        padding: 8px 10px;
        top: -20px;
        left: 100%;
        margin-left: 110px;
    }

    /* Chat box adjustments for smaller screens */
    .chat-box {
        width: 250px; /* Smaller width for mobile */
        top: -20px;
        left: 100%;
        margin-left: 110px;
        padding: 8px;
        border-radius: 8px;
    }

    /* Smaller input and button for mobile */
    .chat-box input[type="text"] {
        padding: 8px;
        font-size: 14px;
    }

    .chat-box button {
        padding: 8px 12px;
        font-size: 14px;
    }
    /* Chat messages container styling */
.chat-messages {
    margin-bottom: 10px;
    overflow-y: auto;
    max-height: 195px; /* Limits height of message area for scrolling */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns messages to the left */
}
}
