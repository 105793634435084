.footer {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    text-align: center;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    width: 100%;
}

.footer-content a {
    color: #4db8ff; /* Light blue for links */
    margin: 0 10px;
    text-decoration: none;
}

.footer-content a:hover {
    text-decoration: underline;
}
